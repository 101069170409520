
// 两位小数验证
const validateValidity = (rule, value, callback) => {
  if (!/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(value)) {
    callback(new Error('最多两位小数！！！'));
  } else {
    callback();
  }
};

// 验证是否是[0-99999]的小数
  const isDecimal=(rule, value, callback) =>{
    if (value < 0 || value > 99999) {
        callback(new Error('请输入[0,99999]之间的数字'));
    }
     else {
        callback();
    }
 
}

export {
  validateValidity,
  isDecimal
}