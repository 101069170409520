
import {isDecimal,validateValidity} from './basicRule'

export const activityFormRule=
  {
    // 要以数组形式展示
      title: [
        { required: true, message: "必须输入活动名称", trigger: "blur" },
        { min: 2, max: 30, message: "长度在 2 到 30 个字符", trigger: "blur" }
      ],
      totalPrice: [
        { required: true, message: "必须输入当前活动酒卡价格", trigger: "blur" },
        { validator:isDecimal, trigger: "blur" },
        {validator:validateValidity, trigger: "blur"  }
      ],
      startDate: [
        {required: true, message: "必须输入活动开始时间" , trigger: "blur"},
      ],
      endDate: [
        { required: true, message: "必须输入活动截止时间", trigger: "blur"},
      ]
};

export const shopFormRule=
  {
    // 要以数组形式展示
      title: [
        { required: true, message: "必须输入店铺名称", trigger: "blur" },
        { min: 2, max: 30, message: "长度在 2 到 15 个字符", trigger: "blur" }
      ]
};