import request from '@/service/lib/request';


export async function queryShopList(params) {
  return request({
      url: '/store/shops',
      method: 'get',
      data:params,
  });
}
export async function queryShop(id) {
  return request({
      url: '/store/shop/'+id,
      method: 'get',
  });
}
export async function editShop(params) {
  return request({
      url: '/store/shop',
      method: 'post',
      data: params,
  });
}
export async function deleteShop(id) {
  return request({
      url: '/store/shop/'+id,
      method: 'delete',
  });
}

export async function dropShop() {
  return request({
      url: '/store/dropshop',
      method: 'get',
  });
}