<template>
  <div class="main-conent main-conent-minheight">
    <el-card class="SearchBox" v-loading="loading">
      <el-row>
        <el-col :span="8">
          <el-button type="primary" @click="() => ToAddPage()">新增</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card v-loading="loading">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="title" label="门店名称" width="180" />
        <el-table-column prop="sort" label="排序序号" width="100" />
        <el-table-column prop="address" label="门店地址" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination layout="prev, pager, next" :total="pageInfo.totalCount" :current-page="pageInfo.pageIndex" :page-size="pageInfo.pageSize" />
      </div>
    </el-card>
    <el-dialog :title="shopDialogState" :visible.sync="showDialog" width="30%" :before-close="handleClose">
      <el-form label-position="right" :model="shopData" label-width="80px" v-loading.lock="loading" ref="editForm" :rules="formRule">
        <el-form-item label="店铺名称" prop="title">
          <el-input v-model="shopData.title"></el-input>
        </el-form-item>
        <el-form-item label="店铺排序" prop="sort">
          <el-input-number v-model="shopData.sort"></el-input-number>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="shopData.address"></el-input>
        </el-form-item>
        <el-row shadow="never" class="">
          <el-button type="primary" @click="Submit('editForm')" :loading="loading">
            提交
          </el-button>
          <el-button @click="CloseDialog">
            关闭
          </el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { queryShopList, editShop, queryShop, deleteShop } from '@/service/shop.js';
import { shopFormRule } from '@/utlis/rules/activityRule.js';
export default {
  data () {
    return {
      showDialog: false,
      loading: false,
      tableData: [],
      pageInfo: {
        pageIndex: 1,
        pageSize: 30,
        totalCount: 0,
      },
      formRule: shopFormRule,
      shopData: {
        id: 0,
        address: '',
        sort: 99,
        title: '',
        lat: '',
        lng: '',
      }
    }
  },
  created () {
    this.DataBinding();
  },
  computed: {
    shopDialogState () {
      if (this.shopData.id > 0)
        return "编辑";
      else
        return "新增";
    }
  },
  methods: {
    //数据加载
    DataBinding () {
      this.loading = true;
      queryShopList(this.pageInfo).then(res => {
        this.tableData = res.data.data;
        this.pageInfo.pageIndex = res.data.pageIndex,
          this.pageInfo.pageSize = res.data.pageSize,
          this.pageInfo.totalCount = res.data.totalCount,

          this.loading = false;
      }).catch(ex => {
        this.loading = false;
      })
    },
    //新增
    ToAddPage () {
      this.showDialog = true;
    },
    //搜索
    searchList () {

    },
    //关闭弹窗
    handleClose () {
      this.shopData.id = 0;
      this.shopData.address = '';
      this.shopData.sort = 99;
      this.shopData.title = '';
    },
    //关闭弹窗
    CloseDialog () {
      this.showDialog = false;
      this.DataBinding();
    },
    //提交表单
    Submit (formName) {
      this.loading = true;
      //表单数据验证
      this.$refs[formName].validate(valid => {
        if (valid) {
          editShop(this.shopData).then(res => {
            if (res.code == 0) {
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.CloseDialog();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
      this.loading = false;
    },
    //编辑门店
    handleEdit (index, data) {
      if (data.id > 0) {
        queryShop(data.id).then(res => {
          this.shopData = res.data;
          this.showDialog = true;
        })
      }
    },
    //删除门店
    handleDelete (index, data) {
      if (data.id > 0) {
        this.$confirm('此操作将永久删门店【' + data.title + '】, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteShop(data.id).then(res => {
            this.DataBinding();
          })
        });
      }
    }
  }
}
</script>
<style scoped Lang="scss">
.SearchBox {
  margin-bottom: 10px;
}
</style>